<template>
  <div class="pricing-tables col-lg-6 col-xl-3 mb-2 mb-lg-0 w-auto pt-5 ">
    <div class="pricing-card">
      <div class="pricing-box" style="max-width:287px;max-height:500px">
        <div class="pricing__header">
          <div class="title card-header">
            <span>{{ price.title.en }}</span>
          </div>
        </div>
        <div class="list-items card-body w-100 p-0">
          <div class="position item">
            <img src="~@assets/img/checkbox-marked-circle.png" alt="checkbox" />
            <span
              >Daily Incom: {{ price.percentMin }}%-{{
                price.percentMax
              }}%</span
            >
          </div>
          <div class="portfolio item">
            <img src="~@assets/img/checkbox-marked-circle.png" alt="checkbox" />
            <span
              >Min.Investment: {{ price.investMin }}
              {{ price.currency.currencySign }}</span
            >
          </div>
          <div class="manual item">
            <img src="~@assets/img/checkbox-marked-circle.png" alt="checkbox" />
            <span
              >Max.Investment: {{ price.investMax }}
              {{ price.currency.currencySign }}</span
            >
          </div>
          <div class="exchange item">
            <img src="~@assets/img/checkbox-marked-circle.png" alt="checkbox" />
            <span>Expiration date: {{ price.term }} days</span>
          </div>
          <div class="exchange item">
            <img src="~@assets/img/checkbox-marked-circle.png" alt="checkbox" />
            <span>Acctual period: Mon.- Sun.</span>
          </div>
        </div>
        <div class="card-footer  w-100 p-0">
          <div class="row ">
            <div class="col">
              <div class="form-group price_input">
                <input
                  v-model="input"
                  class="w-100"
                  :class="{ erorr: error }"
                  id="price_input"
                  placeholder="0"
                  @blur="e => onInput(e)"
                />
                <label for="price_input">{{
                  price.currency.currencySign
                }}</label>
              </div>
              <span
                v-if="errorMessage.length"
                class="text-danger"
                style="font-size:15px "
                >{{ errorMessage }}</span
              >
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 profit">
              <p>Daily profit:</p>
              <p>Total profit:</p>
            </div>
            <div class="col-6 text-end profit_value">
              <p>
                {{ profit }}
              </p>
              <p v-if="!isNaN(profit)">
                {{ (profit * price.term).toFixed(0) }}
              </p>
              <p v-else>{{ profit }}</p>
            </div>
          </div>
        </div>
        <div class="button-box pb-2">
          <button class="started" @click="onSubmit">
            {{ $t('pricing_page.started') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PricingCard',
  props: {
    price: Object,
  },
  data() {
    return {
      error: false,
      input: '',
      compute: 0,
      computeMax: 0,
      errorMessage: '',
    }
  },
  watch: {
    input(value) {
      this.relaceString(value)
    },
  },
  computed: {
    ...mapState('User', ['me']),
    profit() {
      return this.compute
    },
  },
  methods: {
    ...mapActions('Pricing', ['sendInvest']),
    onInput(e) {
      const value = e.target.value
      if (value.length && value < this.price.investMin) {
        this.compute = 'Not min'
        return
      }
      if (value.length && value > this.price.investMax) {
        this.compute = 'Not max'
        return
      }
      this.compute =
        (value * ((this.price.percentMin + this.price.percentMax) * 2)) / 100
    },
    onSubmit() {
      this.errorMessage = ''
      this.error = false
      if (!this.me) {
        console.log('heare')
        return this.$router.push('/auth')
      }
      const form = {
        pricePackage: this.price._id,
        invest: this.input,
      }
      if (
        (form.invest < this.price.investMin) |
        (form.invest > this.price.investMax)
      ) {
        this.error = true
        return
      }
      this.error = false
      this.sendInvest(form).catch(({ data: { message } }) => {
        this.error = true
        this.errorMessage = message
      })
    },
    relaceString(value) {
      this.input = value.replace(/\D/g, '')
    },
  },
}
</script>

<style lang="scss" src="../styles/desktopStyle.scss"></style>
